<template>
  <div style="background: #f5f5f5">
    <!-- 大图部分 -->
    <BigBgimg :list="bigData"></BigBgimg>
    <div class="ser_conter">
      <div class="left">
        <h3>服务协议</h3>
        <ul>
          <li
            :class="index == currentIndex ? 'isActive' : ''"
            v-for="(item, index) in leftData"
            :key="index"
            @click="jump(index)"
          >
            <a>{{ item.name }}</a>
          </li>
        </ul>
      </div>
      <div class="right" ref="Box" @mousewheel="scroll">
        <h2>服务协议</h2>
        <div id="0">
          <h3>一、服务主体及账号</h3>
          <ul>
            <li>
              1.1 本服务由拓幻科技为您提供。“拓幻科技”是合肥拓幻科技有限公司（下称“公司”）运营的网络平台，本协议项下的“拓幻科技”根据不同的语境可能指拓幻网页、移动端及围绕拓幻软件产品提供的相关技术服务。“用户”是指注册、登录、使用拓幻科技产品及服务的使用人，在本协议中称呼为“您”。为了提升用户体验及合规需求，拓幻科技会不定期按照国家相应法律法规对本协议的规定及其不时发布的操作规则进行更新，以便更好地为您提供基于互联网以及移动网相关服务。您只有在同意遵守本协议的全部条款并按照页面上的提示完成全部的注册程序后方可成为拓幻科技网络服务的使用人（即"用户"）。
            </li>
            <li>
              1.2请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款、法律适用和争议解决条款。一旦您点击“同意”，则表示您完全接受本协议项下全部条款，本协议将对您产生法律约束力。请您务必审慎选择是否接受本协议。如果您在相关页面点击确认本协议、或在注册/登录过程中作出（或被拓幻科技视为作出）同意本协议的意思表示、或在拓幻科技以合理方式向您展示/提示/通知本协议或本协议的任何更新版本后仍继续使用拓幻科技提供的服务、或以可能的其他方式作出同意本协议的意思表示，均代表您完全同意本协议的全部条款。
            </li>
            <li>
              1.3 当您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，拓幻科技将给予用户一个用户帐号（以下称“帐号”）及相应的密码，该账号的所有权归拓幻科技，用户完成注册申请手续后，获得该账号的使用权，该帐号和密码由用户负责保管。用户应当对以其帐号实施的所有活动和发生的事件负法律责任。
            </li>
            <li>
              1.4 由于您的拓幻科技账号关联您的个人信息及拓幻科技商业信息，您的拓幻科技账号仅限您本人使用。未经拓幻科技同意，您不得直接或间接转让或授权第三方使用您拓幻科技账号或获取您账号项下信息，否则该授权的行为无效。
            </li>
          </ul>
        </div>
        <div id="1">
          <h3>二、服务内容</h3>
          <ul>
            <li>2.1 拓幻科技网络服务的具体内容由拓幻科技根据实际情况提供。</li>
            <li>
              2.2 拓幻科技提供的部分网络服务为收费的网络服务，用户使用收费网络服务需要向拓幻科技支付一定的费用。对于收费的网络服务，拓幻科技会在用户使用之前给予用户明确的提示，只有用户根据提示确认其愿意支付相关费用，用户才能使用该等收费网络服务。如用户拒绝支付相关费用，则拓幻科技有权不向用户提供该等收费网络服务。
            </li>
            <li>
              2.3 用户理解，拓幻科技仅提供相关的网络服务，除此之外与相关网络服务有关的设备（如个人电脑、其他与接入互联网或移动网有关的装置）及所需的费用（如为接入互联网而支付的电话费及上网费）均应由用户自行负担。
            </li>
          </ul>
        </div>
        <div id="2">
          <h3>三、服务变更、中断或终止</h3>
          <ul>
            <li>
              3.1 鉴于网络服务的特殊性，用户同意拓幻科技有权随时变更、中断或终止部分或全部的网络服务（包括收费网络服务）。如变更、中断或终止的网络服务属于免费网络服务，拓幻科技无需通知用户，也无需对任何用户或任何第三方承担任何责任。
            </li>
            <li>
              3.2 用户理解，拓幻科技需要定期或不定期地对提供网络服务的平台（如互联网网站）或相关的设备进行检修、维护、升级或更新，如因此类情况而造成收费网络服务在合理时间内的中断，拓幻科技无需为此承担任何责任，但拓幻科技应尽可能事先进行通告。
            </li>
            <li>
              3.3 如发生下列任何一种情形，拓幻科技有权随时中断或终止向用户提供本协议项下的网络服务（包括收费网络服务）而无需对用户或任何第三方承担任何责任：
              <ul>
                <li>3.3.1 用户提供的个人资料不真实；</li>
                <li>3.3.2 用户违反法律法规、监管要求或本协议；</li>
                <li>
                  3.3.3 用户在使用收费网络服务时未按规定向拓幻科技支付相应的服务费；
                </li>
                <li>
                  3.3.4 用户侵犯任何第三方的合法权益，包括但不限于专利权、著作权、商标权，或姓名权、名称权、名誉权、荣誉权、肖像权、隐私权等；
                </li>
                <li>3.3.5 用户损害监管部门、国家机关及政府形象；</li>
                <li>
                  3.3.6 用户以任何方式损害拓幻科技的商誉或信誉等合法权益；
                </li>
                <li>
                  3.3.7 拓幻科技有其他合理理由认为需要中断或终止向用户提供服务的。
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div id="3">
          <h3>四、使用规则</h3>
          <ul>
            <li>
              4.1 用户在申请使用拓幻科技网络服务时，有实名认证的义务，用户须根据届时要求，向拓幻科技提供准确的个人资料，如个人资料有任何变动，必须及时更新。用户若为法人或非法人组织时，需提供准确的证照资质等验证资料进行实名认证。
            </li>
            <li>
              4.2 用户不得将其帐号、密码转让或出借予他人使用。如用户发现其帐号遭他人非法使用，应立即通知拓幻科技。因黑客行为或用户的保管疏忽导致帐号、密码遭他人非法使用，拓幻科技不承担任何责任。
              <ul>
                <li>
                  4.2.1 拓幻平台账户类型分为：个人账户；企业账户。<br />
                  个人账户权限，具备上传、下载、购买拓幻平台产品或服务的能力，个人账户所下载、购买的拓幻产品或服务一般仅限于个人使用，无转授权的权利，我们可能会对个人账户的上传或下载行为按照国家的相关规定，要求用户进行一定的验证；<br />
                  企业账户权限：企业主账户具备上传、下载、购买拓幻平台产品或服务的能力，同时可以开通子账户，并对其进行权限管理。<br />
                  企业用户可在注册拓幻账户时申请该账户（简称“主账户”）的次级管理账户（简称“子账户”）用于用户的员工、雇员等从属人员使用，主账户的持有人应妥善保管并分配子账户权限。主账户及子账户的持有人的任何上传、下载、删除等行为，均代表主账户所属自然人、法人和非法人组织的行为。
                </li>
                <li>
                  4.2.2用户若为拓幻科技线下签约客户，仍应按照本协议行使相应的权利履行相应的义务。
                </li>
              </ul>
            </li>
            <li>
              4.3 用户同意拓幻科技在提供服务的过程中以各种方式投放商业性广告或其他任何类型的商业信息（包括但不限于在本协议所涉平台的任何位置上投放广告，在用户上传、传播的内容中投放广告），用户同意并接受拓幻科技通过电子邮件、站内短信、手机短信、网站公告或其他方式向用户发送促销或其他相关商业信息。
            </li>
            <li>
              4.4 如果在服务使用的过程中，涉及作品的上传或下载，则用户在遵守该作品的上传或下载规则的情况下，可以下载、使用该作品，用户亦有权选择公开或不公开该作品。除该上传或下载规则另有约定，用户不得将该素材用于营利性行为。用户保证其对上传到拓幻科技网站上的作品依法享有完整的著作权，并保证其上传行为及上传内容不侵犯他人肖像权、隐私权、名誉权等一切合法权益。对于用户上传的作品，用户仍保留原有的全部权利，同时用户同意并承诺遵守以下约定：
              <ul>
                <li>
                  4.4.1 拓幻科技有权将用户上传的作品用于与本社区或与用户有关的一切以展示、推广、宣传为目的的行为，包括且不限于在拓幻科技或第三方网站进行信息网络传播、显示为网络搜索结果、嵌入代码、结集为汇编作品、制作发行印刷品等。
                </li>
                <li>
                  4.4.2 拓幻科技将采用行业内认可的软件和措施，用以限制其他用户或浏览者未经许可任意复制高清晰度的作品。但拓幻科技无法保证未经用户许可，其上传的作品不会被他人非法复制。拓幻科技不会限制其他用户或浏览者复制网站中的低清晰度作品或缩略图，对此用户同意并给予拓幻科技相应授权。
                </li>
                <li>
                  4.4.3 拓幻科技有权从用户上传的公开作品中选拔一些具有商业价值的图片，放置于本协议所涉平台的主页或其他位置。
                </li>
                <li>
                  4.4.4一旦用户同意本协议、上传符合本协议要求的图片后，平台即取得自行或许可他人使用该等图片的权利，使用方式包括且不限于复制、发行、出租、展览、放映、广播、信息网络传播、改编等一切著作权法规定的方式，用户理解并同意该等图片的使用人可根据实际用图场合和情况决定是否为作者署名。
                </li>
                <li>
                  4.4.5 用户保证拥有完全的民事行为能力签署本协议，并有权作出本协议项下对拓幻科技的授权。
                </li>
              </ul>
            </li>
            <li>
              4.5 用户承诺：遵守法律法规、社会主义制度、国家利益、公民合法利益、公共秩序、社会道德风尚和信息真实性等七条底线的要求。
            </li>
            <li>
              4.6用户不得利用用户帐号或拓幻科技提供的服务从事如下行为：
              <ul>
                <li>
                  （1） 发布、传送、传播、储存违反国家法律法规禁止的内容：
                  <ul>
                    <li>a.反对宪法确定的基本原则的；</li>
                    <li>
                      b.危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；
                    </li>
                    <li>c.损害国家荣誉和利益的；</li>
                    <li>
                      d.歪曲、丑化、亵渎、否定英雄烈士事迹和精神，以侮辱、诽谤或者其他方式侵害英雄烈士的姓名、肖像、名誉、荣誉的；
                    </li>
                    <li>
                      e.宣扬恐怖主义、极端主义或者煽动实施恐怖活动、极端主义活动的；
                    </li>
                    <li>f.煽动民族仇恨、民族歧视，破坏民族团结的；</li>
                    <li>g.破坏国家宗教政策，宣扬邪教和封建迷信的；</li>
                    <li>h.散布谣言，扰乱经济秩序和社会秩序的；</li>
                    <li>
                      i.散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
                    </li>
                    <li>
                      j.侮辱或者诽谤他人，侵害他人名誉、隐私和其他合法权益的；
                    </li>
                    <li>
                      k.煽动非法集会、结社、游行、示威、聚众扰乱社会秩序的；
                    </li>
                    <li>l.以非法民间组织名义活动的；</li>
                    <li>m.含有法律、行政法规禁止的其他内容的。</li>
                  </ul>
                </li>
                <li>
                  （2）发布、传送、传播、储存侵害他人专利权、著作权、商标权、名誉权、肖像权、隐私权、物权、商业秘密或其他合法权利的内容。
                </li>
                <li>
                  （3）发布、传送、传播、储存涉及他人隐私、个人信息或资料的内容。
                </li>
                <li>
                  （4）发布、传送、传播骚扰信息、广告信息、垃圾信息、或含有任何性或性暗示信息的内容。
                </li>
                <li>
                  （5）发布、传送、传播谣言、虚假信息或其他含有不实信息的内容。
                </li>
                <li>
                  （6）发布、传送、传播、储存其他违反法律法规、政策及公序良俗、社会公德或不利于拓幻科技、干扰拓幻科技正常运营和侵犯其他用户或第三方合法权益内容的信息。违反“公序良俗”包括且不限于可能严重误导公众认知、具有欺诈或不正当性质、买卖赞数、买卖粉丝数等一切违反道德、善良风俗或社区公共秩序的情形。
                </li>
                <li>
                  （7）发布、传送、传播、储存含有下列内容的不良信息：
                  <ul>
                    <li>a.使用夸张标题，内容与标题严重不符的；</li>
                    <li>b.炒作绯闻、丑闻、劣迹等的；</li>
                    <li>c.不当评述自然灾害、重大事故等灾难的；</li>
                    <li>d.带有性暗示、性挑逗等易使人产生性联想的；</li>
                    <li>e.展现血腥、惊悚、残忍等致人身心不适的；</li>
                    <li>f.煽动人群歧视、地域歧视等的；</li>
                    <li>g.宣扬低俗、庸俗、媚俗内容的；</li>
                    <li>
                      h.可能引发未成年人模仿不安全行为和违反社会公德行为、诱导未成年人不良嗜好等的；
                    </li>
                    <li>i.其他对网络生态造成不良影响的内容。</li>
                  </ul>
                </li>
                <li>
                  （8）
                  通过发布、删除信息以及其他干预信息呈现的手段侵害他人合法权益或者谋取非法利益。
                </li>
                <li>
                  （9）
                  利用深度学习、虚拟现实等新技术新应用从事法律、行政法规禁止的活动。
                </li>
                <li>
                  （10）
                  通过人工方式或者技术手段实施流量造假、流量劫持以及虚假注册账号、非法交易账号、操纵用户账号等行为，破坏网络生态秩序。
                </li>
                <li>
                  （11）
                  利用党旗、党徽、国旗、国徽、国歌等代表党和国家形象的标识及内容，或者借国家重大活动、重大纪念日和国家机关及其工作人员名义等，违法违规开展网络商业营销活动
                </li>
              </ul>
            </li>
            <li>
              4.8 如发现任何非法使用帐号或帐号出现异常、安全漏洞等情况，用户应立即通告拓幻科技。
            </li>
            <li>
              4.9 拓幻科技有权对用户发布的内容（包括但不限于作品、评论等）进行审核、实时巡查，如果拓幻科技发现或收到他人举报或投诉用户违反本协议约定、法律法规及监管要求的，拓幻科技或其授权的人有权对用户行为进行独立判断，不经通知随时对相关内容进行删除、屏蔽或直接采取其他必要的措施，以减轻用户不当行为造成的影响；并视行为情节严重程度对违规用户账号处以包括但不限于警告、禁言、暂时性或永久封禁违规帐号等处罚，并有权公告处理结果，视情况向有关主管部门报告。拓幻科技也有权依照本协议的规定，拒绝再向该用户提供服务。根据情节严重程度，处罚措施分为：
              <ul>
                <li>
                  1级处罚：站内信警告（用户违反本协议约定条款给予警告1次）；
                </li>
                <li>
                  2级处罚：禁言10日（警告后10日内未按要求处理违规内容的用户）；
                </li>
                <li>
                  3级处罚：封禁账号1至30日（不含30日）（拓幻科技认定情节严重者）；
                </li>
                <li>
                  4级处罚：永久封禁账号并拒绝再次为该用户提供任何服务（拓幻科技认定情节恶劣者）；
                </li>
              </ul>
              用户如因违规行为受到1-4级处罚，处罚期间及时与拓幻科技联系沟通，有效减少因违规行为造成的不良影响，并承诺严格按照本协议约定合法合理使用拓幻科技内服务，拓幻科技将视情节对用户已做出的处罚减轻处理。
            </li>
            <li>
              4.10 拓幻科技针对某些特定的拓幻科技网络服务的使用通过各种方式（包括但不限于网页公告、电子邮件、短信提醒等）作出的任何声明、通知、警示等内容视为本协议的一部分，用户如使用该等拓幻科技网络服务，视为用户同意该等声明、通知、警示的内容。
            </li>
            <li>
              4.11 用户有权随时将上传的作品删除，拓幻科技也可删除任何违反本协议规定的作品（包括由拓幻科技展示在任何第三方网站上的该用户作品）。
            </li>
          </ul>
        </div>
        <div id="4">
          <h3>五、知识产权</h3>
          <ul>
            <li>
              5.1 拓幻科技提供的网络服务中包含的任何文本、图片、图形、音频和/或视频资料均受版权、商标和/或其它财产所有权法律的保护，除另有约定外，未经相关权利人同意，上述资料均不得在任何媒体直接或间接发布、播放、出于播放或发布目的而改写或再发行，或者被用于其他任何商业目的。所有这些资料或资料的任何部分仅可作为私人和非商业用途而保存在某台计算机内。拓幻科技不就由上述资料产生或在传送或递交全部或部分上述资料过程中产生的延误、不准确、错误和遗漏或从中产生或由此产生的任何损害赔偿，以任何形式，向用户或任何第三方负责。
            </li>
            <li>
              5.2 拓幻科技为提供网络服务而使用的任何软件（包括但不限于软件中所含的任何图像、照片、动画、录像、录音、音乐、文字和附加程序、随附的帮助材料）的一切权利均属于该软件的著作权人，未经该软件的著作权人许可，用户不得对该软件进行反向工程（reverse
              engineer）、反向编译（decompile）或反汇编（disassemble）。
            </li>
            <li>
              5.3 用户保证其上传的作品不得侵犯或违反第三方的版权、专利权、商标权、商业秘密、隐私权、名誉权、肖像权、物权、公开权或其它合法权益。
            </li>
          </ul>
        </div>
        <div id="5">
          <h3>六、隐私保护</h3>
          <ul>
            <li>
              6.1 保护用户隐私是拓幻科技的一项基本政策，拓幻科技保证不对外公开或向第三方提供单个用户的注册资料及用户在使用网络服务时存储在拓幻科技的非公开内容，但下列情况除外：
              <ul>
                <li>6.1.1 事先获得用户的明确授权；</li>
                <li>6.1.2 根据有关的法律法规规章要求；</li>
                <li>6.1.3 按照相关政府主管部门的要求；</li>
                <li>6.1.4 为维护社会公众的利益；</li>
                <li>6.1.5 为维护拓幻科技的合法权益。</li>
              </ul>
            </li>
            <li>
              6.2 拓幻科技可能会与第三方合作向用户提供相关的网络服务，在此情况下，如该第三方同意承担与拓幻科技同等的保护用户隐私的责任，则拓幻科技有权将用户的注册资料等提供给该第三方。
            </li>
            <li>
              6.3 在不透露单个用户隐私资料的前提下，拓幻科技有权对整个用户数据库进行分析并对用户数据库进行商业上的利用。
            </li>
          </ul>
        </div>
        <div id="6">
          <h3>七、免责声明</h3>
          <ul>
            <li>
              7.1 用户明确同意其使用拓幻科技网络服务所存在的风险将完全由其自己承担；因其使用拓幻科技网络服务而产生的一切后果也由其自己承担，拓幻科技对用户不承担任何责任。
            </li>
            <li>
              7.2 拓幻科技不担保网络服务一定能满足用户的要求，也不担保网络服务不会中断，对网络服务的及时性、安全性、准确性也都不作担保。
            </li>
            <li>
              7.3 拓幻科技不保证为向用户提供便利而设置的外部链接的准确性和完整性，同时，对于该等外部链接指向的不由拓幻科技实际控制的任何网页上的内容，拓幻科技不承担任何责任。
            </li>
            <li>
              7.4 对于因不可抗力或拓幻科技不能控制的原因造成的网络服务中断或其它缺陷，拓幻科技不承担任何责任，但将尽力减少因此而给用户造成的损失和影响。
            </li>
            <li>
              7.5 用户同意，对于拓幻科技向用户提供的下列产品或者服务的质量缺陷本身及其引发的任何损失，拓幻科技无需承担任何责任；
              <ul>
                <li>7.5.1 拓幻科技向用户免费提供的各项网络服务；</li>
                <li>7.5.2 拓幻科技向用户赠送的任何产品或者服务；</li>
                <li>
                  7.5.3 拓幻科技向收费网络服务用户附赠的各种产品或者服务。
                </li>
              </ul>
            </li>
            <li>
              7.6 用户应自觉遵守著作权法及其他相关法律的规定，不得侵犯拓幻科技及其他用户的合法权益，并就其个人行为自行承担全部责任。用户知晓，拓幻科技没有能力亦无责任监督管理用户对作品的复制行为，其在拓幻科技上传作品即可被其他用户复制，同意自行承担相关风险。如因其他用户非法复制本人上传的作品导致法律纠纷，拓幻科技不承担任何责任，用户不向拓幻科技提出任何索赔。
            </li>
            <li>
              7.7 由于拓幻科技用户的作品均由用户自行上传，拓幻科技没有能力亦无责任审查作品是否存在侵权等情节，用户上传的作品涉及的侵权等法律责任全部由上传用户本人承担。用户同意，如因下载使用其他用户上传的作品发生侵权等法律纠纷，拓幻科技不承担任何责任，用户不向拓幻科技提出任何索赔。
            </li>
            <li>
              7.8 为切实维护权利人的合法权益，拓幻科技依据我国法律法规的规定，对于有理由确信存在明显侵权、违法、违反公共利益等情形的作品时，有权不事先通知并有权删除该等侵权作品。拓幻科技支持权利人（包括但不限于版权人、肖像权人等）将违反或侵权情况告知拓幻科技，如提供符合要求的权利通知和相关证据后，拓幻科技有权删除涉嫌违法或侵权的作品和相关内容。拓幻科技也不承担因采取移除作品等相应措施带来的任何责任，包括但不限于因作品不违法或者侵权指控不成立而给作品上传者带来损害的赔偿责任。
            </li>
            <li>
              7.9 拓幻科技的所有页面广告均来源于合作单位的自动提供，由合作单位和广告发布单位负责。拓幻科技对所有页面广告无法事先审查，对其中的非法、虚假内容不具备监控能力，不承担其带来的法律责任。但是，在接到相关举报并查证属实后，拓幻科技将会立即采取措施屏蔽相应广告内容。
            </li>
            <li>
              7.10 拓幻科技用户所发表的言论仅代表其个人，不代表拓幻科技观点。用户在拓幻科技发表言论的，视为其已经知道并理解这一声明并对其发表的言论承担全部法律责任，拓幻科技对用户发表的言论不承担任何法律责任。
            </li>
          </ul>
        </div>
        <div id="7">
          <h3>八、违约赔偿</h3>
          <ul>
            <li>
              8.1 用户同意保障和维护拓幻科技及其他用户的利益，如因包括但不限于以下情形给拓幻科技及其关联方或任何其他第三人造成损失，用户同意承担由此造成的损害赔偿责任（包括但不限于律师费用、诉讼费、仲裁费及其他合理费用）：
              <ul>
                <li>
                  8.1.1 用户或使用帐号的其他人违反有关法律、法规或本协议项下的任何条款；
                </li>
                <li>
                  8.1.2 任何第三人主张用户上传的作品侵犯、盗用或违反其版权、商标权、专利权、商业秘密、隐私权、名誉权、公开权、肖像权、物权或其它合法权益；
                </li>
                <li>
                  8.1.3 因用户违反本协议约定或者法律法规的规定使用用户上传的作品，导致损害任何第三人合法权益的；
                </li>
                <li>
                  8.1.4 用户擅自将本人帐号提供给第三方使用导致的一切法律纠纷；
                </li>
                <li>
                  8.1.5 因用户违反本协议约定或者法律法规的规定发表不当或或非法言论导致损害任何第三人合法权益的；
                </li>
                <li>
                  8.1.6 其他给拓幻科技及其关联方或任何其他第三人造成损失的情形。
                </li>
              </ul>
            </li>
            <li>
              8.2 因用户的原因导致的任何纠纷、责任等，以及用户或用户帐号违反法律法规或本协议约定引发的任何后果，均由用户独立承担全部责任，与拓幻科技无关。
            </li>
          </ul>
        </div>
        <div id="8">
          <h3>九、协议修改</h3>
          <ul>
            <li>
              9.1 拓幻科技有权随时修改本协议的任何条款，一旦本协议的内容发生变动，拓幻科技将会通过适当方式向用户提示修改内容。
            </li>
            <li>
              9.2 如果不同意拓幻科技对本协议相关条款所做的修改，用户有权停止使用网络服务。如果用户继续使用网络服务，则视为用户接受拓幻科技对本协议相关条款所做的修改。
            </li>
          </ul>
        </div>
        <div id="9">
          <h3>十、通知送达</h3>
          <ul>
            <li>
              10.1 本协议项下拓幻科技对于用户所有的通知均可通过网页公告、电子邮件或常规的信件传送等方式进行；该等通知于发送之日视为已送达收件人。
            </li>
            <li>
              10.2 用户对于拓幻科技的通知应当通过拓幻科技对外正式公布的通信地址、传真号码、电子邮件地址等联系信息进行送达。
            </li>
          </ul>
        </div>
        <div id="10">
          <h3>十一、法律管辖</h3>
          <ul>
            <li>
              11.1 本协议的订立、执行和解释及争议的解决均应适用中国法律并受中国法院管辖。
            </li>
            <li>
              11.2 各方确认：以用户在本协议所涉网站注册信息中所留的住所地（或地址）、电子邮箱及手机号码，作为各方之间往来以及仲裁机构仲裁和法院执行时的相关资料、通知、法律文书（包括但不限于仲裁裁决书、调解书等）的送达地址。各方确认，本协议各方、仲裁机构或者法院向本协议各方发出任何通知、法律文书（包括但不限于仲裁裁决书、调解书等），均可以邮政特快专递、快递、电子邮件或者短信方式或者仲裁机构或法院认为合适的其他方式发出，发送一方正确填写被送达方的住所地（或地址）、电子邮箱或手机号码即视为送达。如一方需变更住所地（或地址）、电子邮箱或者手机号码而未书面告知另一方、仲裁机构或法院，任何一方当事人、仲裁机构或者法院向上述原住所地（或地址）、原电子邮箱或者原手机号码发送法律文件，以及发送的法律文件被拒绝接收或者邮件系统或者短信系统退回的，均视为已经完成送达。
            </li>
            <li>
              11.3 因本协议产生或者与本协议有关的所有争议（包括但不限于合同纠纷、侵权纠纷）各方均同意提交南京仲裁委员会仲裁，仲裁地点在南京，并由败诉方承担仲裁费、律师费、公告费、保全费、保全担保费、鉴定费、评估费、执行费、送达费、差旅费、食宿费、交通费等费用。
            </li>
          </ul>
        </div>
        <div id="11">
          <h3>十二、其他规定</h3>
          <ul>
            <li>
              12.1 本协议所称“拓幻科技”是指本协议所涉网站经营者合肥拓幻网络科技有限公司及现有和或将来设立的相关关联公司的单称或合称。
            </li>
            <li>
              12.2 本协议构成双方对本协议之约定事项及其他有关事宜的完整协议，除本协议规定的之外，未赋予本协议各方其他权利。
            </li>
            <li>
              12.3 如本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效并且有约束力。
            </li>
            <li>（以下无正文）</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BigBgimg from "@/components/BigBgimg/index"; //大图
export default {
  components: { BigBgimg },
  data() {
    return {
      // 大图数据
      bigData: {
        topTitle: "服务协议",
        topText: "完整共赢的协议为您提供更规划化的服务，更轻松的业务合作",
        topBtn: "联系我们",
        topBg: require("@/assets/home/ser/服务协议首图.png"),
      },
      leftData: [
        { name: "服务主体及账号", id: "0" },
        { name: "服务内容", id: "1" },
        { name: "服务变更、中断或终止", id: "2" },
        { name: "使用规则", id: "3" },
        { name: "知识产权", id: "4" },
        { name: "隐私保护", id: "5" },
        { name: "免责声明", id: "6" },
        { name: "违约赔偿", id: "7" },
        { name: "协议修改", id: "8" },
        { name: "通知送达", id: "9" },
        { name: "法律管辖", id: "10" },
        { name: "其他规定", id: "11" },
      ],
      currentIndex: 0, //
    };
  },
  methods: {
    scroll(e) {
      let y = this.$refs.Box.scrollTop;
      if (y >= 0 && y < 499) {
        this.currentIndex = 0;
      } else if (y >= 499 && y < 681) {
        this.currentIndex = 1;
      } else if (y >= 681 && y < 1079) {
        this.currentIndex = 2;
      } else if (y >= 1079 && y < 3205) {
        this.currentIndex = 3;
      } else if (y >= 3205 && y < 3483) {
        this.currentIndex = 4;
      } else if (y >= 3483 && y < 3761) {
        this.currentIndex = 5;
      } else if (y >= 3761 && y < 4495) {
        this.currentIndex = 6;
      } else if (y >= 4495 && y < 4797) {
        this.currentIndex = 7;
      }
    },
    // 点击跳转
    jump(i) {
      let y = document.getElementById(i).offsetTop;
      console.log(y);
      this.currentIndex = i;
      this.$refs.Box.scrollTop = y;
    },
  },
  mounted() {
    this.$refs.Box.addEventListener("scroll", this.scroll);
  },
};
</script>
<style lang="scss" scoped>
.ser_conter {
  width: 1140px;
  margin: auto;
  padding: 81px 0;
  display: flex;
  justify-content: center;
  .left {
    width: 250px;
    height: 700px;
    background: #ffffff;
    margin-right: 40px;
    h3 {
      margin: auto;
      font-size: 16px;
      font-weight: 500;
      color: #222222;
      margin: 24px 0 24px 31px;
    }
    ul {
      font-weight: 400;
      line-height: 38px;
      font-size: 14px;
      li {
        height: 50px;
        line-height: 50px;
        padding-left: 31px;
        cursor: pointer;
      }
      a {
        color: #666666;
      }
      li:hover a {
        color: #4c8cf5;
      }
    }
    .isActive {
      background: #f4f7fe;
      border-right: 2px solid #4c8cf5;
      a {
        color: #4c8cf5;
      }
    }
  }
  .right {
    width: 850px;
    height: 1070px;
    background: #ffffff;
    padding: 35px;
    overflow-y: scroll;
    position: relative;
    h2 {
      width: 150px;
      margin: auto;
      margin-bottom: 28px;
      font-size: 30px;
      font-weight: 500;
      color: #222222;
      line-height: 38px;
    }
    h3 {
      font-size: 16px;
      font-weight: 500;
      color: #222222;
      line-height: 38px;
    }
    li {
      font-size: 14px;
      font-weight: 400;
      color: #333;
      line-height: 24px;
    }
  }
}
</style>